.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#headerspan {
  margin-left: auto;
}

body {
  background-color: black;
  color: white
}

.sokbar {
  width: 100%;
  /* Gör input-fältet lika brett som sin förälder */
  padding: 15px;
  /* Ger tillräckligt med padding för att skapa en större input */
  font-size: 24px;
  /* Justerar fontstorlek för större text */
  border: none;
  /* Tar bort standardramar */
  background-color: black;

  border-bottom: 3px solid #ff8000;
  /* Blå ram endast på botten */
  outline: none;
  /* Tar bort den blå ramen som visas vid fokus */
  transition: background-color 0.3s ease;
  /* Mjuk övergång för hover-effekt */
  color: white;
  /* Sätter textfärgen */
  text-transform: uppercase;
  /* Gör texten versaler */
  box-sizing: border-box;
  /* Inkluderar padding och border i elementets totala bredd */
  text-align: center;
}

.fondknapp {
  width: 100%;
  /* Gör knappen lika bred som sin förälder */
  padding: 15px;
  /* Ger tillräckligt med padding för en stor knapp */
  font-size: 18px;
  /* Justerar fontstorlek för större text */
  background-color: black;
  /* Gör bakgrunden vit */
  border: none;
  /* Tar bort standardram */
  border-bottom: 3px solid #ff8000;
  /* Blå ram endast på botten */
  cursor: pointer;
  /* Visar en pekare när man hovrar över knappen */
  transition: background-color 0.3s ease;
  /* Mjuk övergång för hover-effekt */
  color: white;
  /* Sätter textfärgen */
  text-transform: uppercase;
  /* Gör texten versaler */
  text-align: left;
}

.fondknapp:hover {
  background-color: darkgray;
  cursor: pointer;
}


.headerbutton {
  background: none;
  transition-duration: 0.2s;
  font-size: 1.5rem;
  margin-right: 0.5rem;
  padding: 0.2rem 0.5rem;
  font-weight: bolder;
  border: none;
  color: black
}

.headerbutton:hover {
  color: rgb(44, 44, 44);
  cursor: pointer;
  border-radius: 4px;
}

main {
  flex-grow: 1;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.hemskarm {
  display: inline-flex;
  justify-content: space-between;
  margin: 30pt auto 50pt 10pt;
  gap: 10pt
}

.hometable {
  text-align: center;
  margin-top: 40pt;
  margin-left: 20pt;
  margin-right: 30pt;
  border-spacing: 10pt;
}

.wrapper {
  border: 2px solid #ff8000;
  padding: 10pt;
  margin-top: 10pt;
  border-radius: 15px;
}

.tr {
  cursor: pointer;
}

.tr:hover {
  background-color: #dfa76e;
}